import React, { useState } from 'react'

const ToggleModal = ({ toggle, content }) => {
  const [isShown, setIsShown] = useState(false)
  const hide = () => setIsShown(false)
  const show = () => setIsShown(true)

  return (
    <>
      {toggle(show)}
      {content(hide, isShown)}
    </>
  )
}

export default ToggleModal
