import { createPortal } from 'react-dom'
import usePortal from './usePortal'

/**
 * @example
 * <Portal>
 *   <p>Content at body level</p>
 * </Portal>
 */

const Portal = ({ id, children }) => {
  const target = usePortal(id)
  if (target) {
    return createPortal(children, target)
  } else {
    return null
  }
}

export default Portal
