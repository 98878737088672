import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import CloseIcon from '../images/close-x.svg'
import Portal from './Portal'

const Overlay = styled.div`
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 180ms;

  &.modal-active {
    pointer-events: initial;
    opacity: 1;
    transition: opacity 220ms;
    background-color: rgba(0, 0, 0, 0.25);
  }
`

const CloseModal = styled.button`
  z-index: 1;
  position: fixed;
  right: 0;
  margin: 10px;
  padding: 10px;
  line-height: 0;
  background-color: white;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.lightBorder};
  cursor: pointer;
  color: ${props => props.theme.colors.lightGray};
`

const Wrapper = styled.div`
  position: relative;
  min-width: calc(100vw - (var(--site-x-padding) * 2));
  transform: scale(0.6);
  transition: transform 200ms;

  .modal-active & {
    transform: scale(1);
    transition: transform 200ms;
  }

  @media screen and (min-width: ${props =>
      props.theme.containerWidths.smallMedium}) {
    width: ${props => props.theme.containerWidths.smallMedium};
    min-width: 0;
  }
`

const ModalCard = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 10px * 2);
  height: max-content;
  max-height: 82vh;
  min-height: 0;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  padding: ${props => props.theme.modal.padding};
  box-shadow: ${props => props.theme.modal.boxShadow};

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    padding: 40px 40px 75px;
  }
`

const Modal = ({ hide, isShown, children }) => {
  const targetRef = useRef(null)

  useEffect(() => {
    function scrollLocking() {
      if (isShown) {
        disableBodyScroll(targetRef.current)
      } else {
        enableBodyScroll(targetRef.current)
      }
    }

    scrollLocking()

    return function cleanup() {
      clearAllBodyScrollLocks()
    }
  }, [isShown])

  return (
    <Portal id="modal">
      <Overlay className={isShown ? 'modal-active' : null} onClick={hide}>
        <Wrapper onClick={e => e.stopPropagation()}>
          <CloseModal onClick={hide}>
            <CloseIcon width="14px" />
          </CloseModal>
          <ModalCard ref={targetRef}>{children}</ModalCard>
        </Wrapper>
      </Overlay>
    </Portal>
  )
}

export default Modal
